import './App.css';
import { Inicio } from "./components/interfaces/inicio";
import { Menu } from "./components/interfaces/menu";
import {MenuVendedor} from "./components/interfaces/menuVendedor";
import { Fabrica } from "./components/interfaces/fabrica";
import { Informes } from "./components/interfaces/informes";
import { BuscarButaca } from './components/interfaces/fabrica/buscar-butaca';
import { RegistrarButaca } from './components/interfaces/fabrica/registrar-butaca';
import { Terminadas30Dias } from './components/interfaces/informes/terminadas30Dias';
import { TerminadasVsIngresadas } from './components/interfaces/informes/terminadasVsIngresadas';
import { DiasEstancada } from './components/interfaces/informes/diasEstancada';
import { DiasIngreso } from './components/interfaces/informes/diasIngreso';
import { Cortadas } from './components/interfaces/informes/cortadas';
import { DateRangePicker } from './components/interfaces/informes/date';
import{
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import React, { useEffect } from 'react';
import { botonLogout } from './components/services/manejo-login';
import { FiltroMes } from './components/interfaces/informes/filtroMes';
import { HomologarButaca } from './components/interfaces/fabrica/homologar-butaca';
import { FechaUsuarioButaca } from './components/interfaces/informes/fechaUsuarioButaca';
import { CompradorHomologadas } from './components/interfaces/informes/comprador';

function App() {

  const handleWindowClose = () => {
    botonLogout();
  };
  
  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, []);

  return(
    <>
      <div className='todo'>
        <Router>
          <div className="header">
            <div className="start">
              <Link to="/">
                <img className="pic logo" src='GP3.png'/>
              </Link>
            </div>
            <div className="barras">
              <i className="fa-solid fa-bars"></i>
            </div>
          </div>
          <div className='contenido'>
            <Routes>
              <Route exact path="/" element={<Inicio/>}></Route>
              <Route path="/menu" element={<Menu/>}></Route>
              <Route path="/menuVendedor" element={<MenuVendedor/>}></Route>
              <Route path="/fabrica" element={<Fabrica/>}></Route>
                <Route path="/fabrica/registrarButaca" element={<RegistrarButaca/>}></Route>
                <Route path="/fabrica/buscarButaca" element={<BuscarButaca/>}></Route>
                <Route path="/fabrica/homologarButacas" element={<HomologarButaca/>}></Route>
              <Route path="/informes" element={<Informes/>}></Route>
                {/*<Route path="/informes/filtroMes" element={<FiltroMes/>}></Route>
                <Route path="/informes/terminadas30Dias" element={<Terminadas30Dias/>}></Route>
                <Route path="/informes/terminadasVsIngresadas" element={<TerminadasVsIngresadas/>}></Route>
                <Route path="/informes/cortadas" element={<Cortadas/>}></Route>*/}
                <Route path="/informes/diasIngreso" element={<DiasIngreso/>}></Route>
                <Route path="/informes/diasEstancada" element={<DiasEstancada/>}></Route>
                <Route path='/informes/datePicker' element={<DateRangePicker/>}></Route>
                <Route path='/informes/comprador' element={<CompradorHomologadas/>}></Route>
            </Routes>
          </div>
        </Router>
      </div>
    </>
  )
}

export default App;

