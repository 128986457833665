import { BotonLogout } from '../elementos/btnLogout';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { recuentoStockListas } from '../services/manejo-butacas';

export const MenuVendedor = ()=> {
    const history = useNavigate();

    //let c = 0;

    useEffect(() => {
        //c += 1;
        //if (c===2) {
            if (sessionStorage.token===undefined){history('/')};
            recuentoStockListas(history);
        //};
    }, []);

    return(
        <div className="fabricaInterface">
            <BotonLogout h={history} />
            <h2>VENDEDOR</h2>
        </div>
    )
}